<template>
  <b-card-actions action-collapse title="Daftar Paket Pengiriman">
    <!-- ref="refreshCard"
    action-refresh
    @refresh="refreshStop('refreshCard')" -->
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click.prevent="add" variant="primary">
          <feather-icon icon="PlusIcon" class="mr-50" />
          {{ $t('Add') }}
        </b-button>
        <b-modal v-model="showModalAdd" id="modal-tambah" cancel-variant="secondary" ok-title="Add"
          cancel-title="Batal" centered title="Form Add">
          <b-form>
            <b-form-group>
              <label for="package_name">Paket Pengiriman</label>
              <b-form-input v-model="package_name" id="package_name" placeholder="Nama Paket Pengiriman" />
              <label for="base_price">Harga Dasar</label>
              <b-form-input v-model="base_price" id="base_price" placeholder="Harga Dasar" />
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="primary" @click="submit"> Add </b-button>
              <b-button size="sm" variant="danger" @click="resetForm"> Batal </b-button>
            </section>
          </template>
        </b-modal>
        <b-modal v-model="showModalEdit" id="modal-ubah" cancel-variant="secondary" ok-:title="$t('Edit')" cancel-title="Batal"
          centered title="Form Ubah">
          <b-form>
            <b-form-group>
              <label for="package_name">Nama Paket Pengiriman:</label>
              <b-form-input v-model="package_name" id="package_name" type="package_name" placeholder="Nama Paket" />
              <label for="base_price">Harga Dasar:</label>
              <b-form-input v-model="base_price" id="base_price" type="base_price" placeholder="Harga Dasar" />
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="info" @click="submit"> Ubah </b-button>
              <b-button size="sm" variant="danger" @click="resetForm"> Batal </b-button>
            </section>
          </template>
        </b-modal>
        <b-modal v-model="showModalDetail" id="modal-detail" size="md" cancel-variant="secondary" ok-only
          ok-title="Close" centered title="Detail Paket Pengiriman">
          <b-form>
            <b-form-group>
              <label for="detail_package_name">Nama Paket Pengiriman:</label>
              <b-form-input v-model="detail.package_name" id="detail_package_name" readonly />
              <label for="detail_base_price">Harga Dasar:</label>
              <b-form-input v-model="detail.base_price" id="detail_base_price" readonly />
            </b-form-group>
            <b-form-group>
              <b-button size="sm" variant="primary" @click="showCreateRuleModal">Add Aturan</b-button>
            </b-form-group>
            <b-table :items="detail.price_tier_rules" :fields="priceTierFields" striped hover small>
              <template #cell(price_per_km)="row">
                {{ row.item.price_per_km }} / km
              </template>
              <template #cell(minimal_distance)="row">
                {{ row.item.minimal_distance }} km
              </template>
              <template #cell(actions)="row">
                <b-button title="'Edit Rule'" size="sm" @click="editRule(row.item)"
                  variant="outline-info" class="mr-1">
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button title="'Delete Rule'" size="sm"
                  @click="deleteRule({ package_id: detail.id, package_name: detail.package_name, package_tier_id: row.item.id })"
                  variant="outline-danger">
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
          </b-form>
        </b-modal>
        <b-modal v-model="showModalEditRule" id="modal-edit-rule" cancel-variant="secondary" ok-title="Update"
          cancel-title="Cancel" centered title="Ubah Aturan">
          <b-form @submit.prevent="submitEditedRule">
            <b-form-group>
              <label for="rule_price_per_km">Harga per KM</label>
              <b-form-input v-model="rulePricePerKm" id="rule_price_per_km" type="number"
                placeholder="Masukan Harga per KM" />
            </b-form-group>
            <b-form-group>
              <label for="rule_minimal_distance">Jarak Minimal</label>
              <b-form-input v-model="ruleMinimalDistance" id="rule_minimal_distance" type="number"
                placeholder="Masukan Jarak Minimal" />
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="primary" type="submit">Update</b-button>
              <b-button size="sm" variant="danger" @click="cancelEdit">Cancel</b-button>
            </section>
          </template>
        </b-modal>
        <b-modal v-model="showModalCreateRule" id="modal-create-rule" cancel-variant="secondary" ok-title="Create"
          cancel-title="Cancel" centered title="Buat Aturan">
          <b-form @submit.prevent="submitNewRule">
            <b-form-group>
              <label for="new_rule_price_per_km">Harga per KM</label>
              <b-form-input v-model="newRule.price_per_km" id="new_rule_price_per_km" type="number"
                placeholder="Masukan Harga per KM" />
            </b-form-group>
            <b-form-group>
              <label for="new_rule_minimal_distance">Jarak Minimal</label>
              <b-form-input v-model="newRule.minimal_distance" id="new_rule_minimal_distance" type="number"
                placeholder="Masukan Jarak Minimal" />
            </b-form-group>
          </b-form>
          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="primary" @click="submitNewRule">Create</b-button>
              <b-button size="sm" variant="danger" @click="resetCreateRuleForm">Cancel</b-button>
            </section>
          </template>
        </b-modal>

      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group :label="$t('Sort')" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group :label="$t('Filter')" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search"  />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
          :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
          :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
          <template #cell(no)="row">
            <strong class="text-center">
              {{ row.index + 1 }}
            </strong>
          </template>
          <template #cell(actions)="row">
            <b-button title="'Ubah'" size="sm" @click="edit(row.item)" class="mr-1"
              variant="outline-info">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button title="'Detail'" size="sm" @click="showDetail(row.item.id)" class="mr-1"
              variant="outline-primary">
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button title="'Hapus'" size="sm" @click="remove(row.item)" variant="outline-danger">
              <feather-icon icon="TrashIcon" />
            </b-button>

          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
          class="my-0" />
      </b-col>
    </b-row>
  </b-card-actions>
</template>


<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BTable, VBTooltip, BAvatar, BBadge, BModal, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton } from "bootstrap-vue";

export default {
  components: {
    BCardActions,
    VBTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      showModalAdd: false,
      showModalEdit: false,
      showModalDetail: false,
      showModalEditRule: false,
      showModalCreateRule: false,

      newRule: {
        price_per_km: '',
        minimal_distance: ''
      },
      id: null,
      package_name: null,
      base_price: null,
      detail: {
        id: '',
        package_name: '',
        base_price: '',
        price_tier_rules: []
      },
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "no", label: "No" },
        { key: "package_name", label: "Nama Paket", sortable: true },
        { key: "base_price", label: "Harga Dasar", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      priceTierFields: [
        { key: "price_per_km", label: "Harga per KM" },
        { key: "minimal_distance", label: "Jarak Minimal" },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.getData();
  },
  methods: {
    add() {
      this.id = null;
      this.fungsi = null;
      this.activeAction = "tambah";
      this.showModalAdd = true;
      this.showModalEdit = false;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Paket Pengiriman "${item.package_name}" ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("shippingPackage/save", item)
            .then(() => {
              this.pesanBerhasilHapus();
              this.resetForm();
              this.getData();
            })
            .catch((e) => {
              this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.package_name = null;
      this.base_price = null;
      this.showModalAdd = false;
      this.showModalEdit = false;
    },
    submit() {
      if (this.package_name == null || this.base_price == "") {
        this.pesanGagalSimpan();
        return false;
      }
      let payload = {
        package_name: this.package_name,
        base_price: this.base_price,
      };

      if (this.id) {
        payload.id = this.id;
      }
      if (this.fungsi) {
        payload.fungsi = this.fungsi;
      }

      this.$store
        .dispatch("shippingPackage/save", payload)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.package_name = null;
          this.base_price = null;
          this.showModalAdd = false;
          this.showModalEdit = false;
          this.getData();
          this.pesanBerhasilSimpan();
        })
        .catch((e) => console.error(e));
    },
    edit(item) {
      let { id, package_name, base_price, fungsi = 2 } = item;
      this.id = id;
      this.fungsi = fungsi;
      this.package_name = package_name;
      this.base_price = base_price;
      this.showModalEdit = true;
    },
    showDetail(id) {
      this.$store
        .dispatch("shippingPackage/getDataById", id)
        .then((response) => {
          this.detail = response;
          this.showModalDetail = true;
        })
        .catch((e) => {
          console.error(e);
          this.pesanGagalSimpan();
        });
    },
    editRule(item) {
      let { id, minimal_distance, price_per_km } = item;
      this.id = id,
        this.ruleMinimalDistance = minimal_distance,
        this.rulePricePerKm = price_per_km,
        console.log(id, minimal_distance, price_per_km);
      this.showModalEditRule = true;
    },
    showCreateRuleModal() {
      this.resetCreateRuleForm();
      this.showModalCreateRule = true;
    },
    submitNewRule() {
      // Assuming the new rule needs to be added to `detail.price_tier_rules` and saved to the backend
      if (this.newRule.price_per_km && this.newRule.minimal_distance) {
        const newRule = { ...this.newRule, shipping_package_id: this.detail.id };
        console.log(this.detail.id);
        // Here, you should dispatch an action or call a method to save the new rule to the backend
        this.$store.dispatch('shippingPackage/createRule', newRule).then(() => {
          this.detail.price_tier_rules.push(newRule); // Update local detail
          this.pesanBerhasilSimpan(); // Show success message
          this.showModalCreateRule = false; // Hide create rule modal
        }).catch((error) => {
          console.error(error);
          this.pesanGagalSimpan(); // Show error message
        });
      } else {
        this.pesanGagalSimpan(); // Show error message for invalid input
      }
    },
    resetCreateRuleForm() {
      this.newRule.price_per_km = '';
      this.newRule.minimal_distance = '';
    },
    deleteRule(param) {
      console.log(param);
      // Display a confirmation dialog
      this.$swal({
        title: "Anda yakin?",
        text: `Data Aturan per KM pada ${param.package_name} ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {

        if (result.value) {
          this.$store.dispatch("shippingPackage/deleteRule", param.package_tier_id)
            .then(() => {
              this.pesanBerhasilHapus();
              this.resetForm();
              this.showDetail(param.package_id);
            })
            .catch((e) => {
              this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },

    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menyimpan data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menghapus data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menghapus data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    getData() {
      this.$store
        .dispatch("shippingPackage/getData", {})
        .then(() => {
          this.items = this.$store.state.shippingPackage.datas;
          this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
